import React from "react"
import LinearGauge from "../../elements/LinearGauge"
import styles from "./styles.module.css";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { blue, red } from '@mui/material/colors';
import axios from "axios";

import authHeader from "../../services/auth-header"

const ColorButtonBlue = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[50]),
  backgroundColor: blue[50],
  '&:hover': {
    backgroundColor: blue[100],
  },
	marginRight:'20px',
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[50]),
  backgroundColor: red[50],
  '&:hover': {
    backgroundColor: red[100],
  },
	marginRight:'20px',
}));


function updateDataListRemoveBlacklist(filename, setResumes, resumes, setAllResumes, allResumes){
  const newData = resumes.map(obj => {
    if (obj.filename === filename) {
      return {...obj, blacklist: false};
    }
    return obj;
  });

  setResumes(newData);

  const newAllData = allResumes.map(obj => {
    if (obj.filename === filename) {
      return {...obj, blacklist: false};
    }
    return obj;
  });

  setAllResumes(newAllData);

};

function updateDataListAddBlacklist(filename, setResumes, resumes, setAllResumes, allResumes){
  const newData = resumes.map(obj => {
    if (obj.filename === filename) {
      return {...obj, blacklist: true};
    }
    return obj;
  });

  setResumes(newData);

  const newAllData = allResumes.map(obj => {
    if (obj.filename === filename) {
      return {...obj, blacklist: true};
    }
    return obj;
  });

  setAllResumes(newAllData);

};

export default function PresentationCV(props) {

  const base64toBlob = (mimeType, base64) => {
      const bytes = atob(base64);
      let length = bytes.length;
      let out = new Uint8Array(length);
      while (length--) {
          out[length] = bytes.charCodeAt(length);
      }
      return new Blob([out], { type: mimeType });
  };

  const openFileInTab = async () => {
    const url = "http://mongo.findme.2kfe.fr/api/functions_database/getBase64";
    const cursor = await axios.post(url, {filename: props.filename}, { headers: authHeader() });
    const base64 = cursor.data
    const mimeType = props.mimeType
    const blob = base64toBlob(mimeType, base64);
    const urlFile = URL.createObjectURL(blob)
    window.open(urlFile, '_blank', 'noopener,noreferrer');
  }

  const addToBlacklist = async () => {
    const url = "http://mongo.findme.2kfe.fr/api/functions_database/addToBlacklist";
    await axios.post(url, {filename: props.filename}, { headers: authHeader() });
  };

  const removeFromBlacklist = async () => {
    const url = "http://mongo.findme.2kfe.fr/api/functions_database/removeFromBlacklist";
    await axios.post(url, {filename: props.filename}, { headers: authHeader() });
  };

    return (
      <div>
        <div className={styles.CV_pres}>
          <div className={styles.person_pres}>
            <b style ={{'color': props.blacklist ? 'red' : "black", fontFamily:'Arial'}}>{props.filename}</b>
            <p style ={{fontFamily:'Arial'}}>{props.email}</p>
            <p style ={{fontFamily:'Arial'}}>Ajouté le {props.addedTime}</p>
          </div>

          <ColorButtonBlue variant="outlined" className={styles.Ouvrir_fichier} onClick={openFileInTab}>Ouvrir</ColorButtonBlue>
          <div>
            <LinearGauge accuracy = {props.accuracy}/>
          </div>


          {props.blacklist?
            <ColorButtonRed variant="outlined" color="error" onClick={() => {removeFromBlacklist(props); updateDataListRemoveBlacklist(props.filename, props.setResumes, props.resumes, props.setAllResumes, props.allResumes)}} className={styles.rem_blacklister}>Enlever de la blacklist</ColorButtonRed>:
            <ColorButtonRed variant="outlined" color="error" onClick={() => {addToBlacklist(props); updateDataListAddBlacklist(props.filename, props.setResumes, props.resumes, props.setAllResumes, props.allResumes)}} className={styles.blacklister}>Blacklister</ColorButtonRed>
          }
        </div>
        <hr  style={{
            color: '#000000',
            marginLeft: 80,
            marginRight: 80,
            opacity:0.3
        }}/>
      </div>
    )
}

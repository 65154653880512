import React , { useState } from 'react';
import styles from "./styles.module.css";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import axios from "axios";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import authHeader from "../services/auth-header"


const ColorButtonRed = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[50]),
  backgroundColor: red[50],
  '&:hover': {
    backgroundColor: red[100],
  },
	marginRight:'20px',
}));

function updateDeleteUser(username, setUserList, userList){
  const newData = userList.filter(object => {
    return object.username !== username;
  });

  setUserList(newData);
};

export default function UserList(props) {

  const [openDialog, setOpenDialog] = useState(false)


  const deleteUser = async () => {
    // console.log("blablabla ")
    const url = "http://mongo.findme.2kfe.fr/api/functions_database/deleteUser";
    await axios.post(url, {username: props.username}, { headers: authHeader() });
  };

  const openDialogPopUp = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

    return (
      <div>
        <div className={styles.user_pres}>

          <div>
            <b style ={{'color': "black", fontFamily:'Arial'}}>{props.username}</b>
            <p style ={{fontFamily:'Arial'}}>{props.email}</p>
          </div>
          <ColorButtonRed variant="outlined" color="error" onClick={() => {openDialogPopUp()}}  startIcon={<DeleteIcon />} className={styles.rem_blacklister}>Supprimer</ColorButtonRed>

        </div>
        <hr  style={{
            color: '#000000',
            marginLeft: 90,
            marginRight: 90,
            opacity:0.3
        }}/>

        <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Etes vous sûr de vouloir supprimer cet utilisateur: ${props.username}`}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={() => {deleteUser(); handleClose(); updateDeleteUser(props.username, props.setUserList, props.userList)}} autoFocus>
            Oui, supprimer
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    )
}

import React from "react";
import BasicTabs from '../pages/tabs'

const Main = (props) => {
  return (
    <div className="tabsSize">
				<BasicTabs allResumes={props.allResumes} setAllResumes={props.setAllResumes}/>
		</div>
  )
}

export default Main;

import React , { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import styles from "./styles.module.css";
import Button from '@mui/material/Button';
import PresentationCV_updated from "./PresentationCV_updated"
import PresCVSansDic from './PresCVSansDic'
import CircularProgress from '@mui/material/CircularProgress';

import authHeader from "../../services/auth-header"

import axios from "axios";

export default function BlacklistPage(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [resumesBlacklist, setResumesBlacklist] = useState([]);
  const [resumesBlacklistSearch, setResumesBlacklistSearch] = useState([]);
  const [searched, setSearched] = useState(false);
  
  const onChangeKeyword = (event) => {
    setKeyword(event.target.value);
  };

  const handleKeyPress = (event) => {
    if(event.key === 'Enter' && event.target.value.length > 0){
      setKeyword(keyword)
      onSearchResumes(keyword)
    }
  }

  function trimSpace(word) {
    return word.trim();
  }


  function getArrFromSearch(inputValue){
    let arr = inputValue.split(',')
    arr = arr.map(trimSpace)
    arr = arr.filter(arr => arr.length > 0)
    return arr
  }



  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      // const url = "http://mongo.findme.2kfe.fr/api/functions_database/displayBlacklist";
      // const cursor = await axios.post(url, {}, { headers: authHeader() });
      // let data = cursor.data
      const url = "http://mongo.findme.2kfe.fr/api/functions_database/getValidation";
      const cursor = await axios.post(url, {}, { headers: authHeader() });
      
      const newArray = props.allResumes.filter(function (el) { return el.blacklist == true });
      // console.log(newArray)
      setResumesBlacklist(newArray)
      setIsLoading(false)
    }
    fetchData();
  }, []);

  async function getListResultBlacklist(listWords){
    const results = resumesBlacklist
    let finalArr = []
    for(let i=0; i < results.length; i++){
      const cv = results[i]
      const {accuracy, dic, total_count} = getAccuracyWords(cv, listWords)
      if(accuracy > 0){
        cv["accuracy"] = accuracy
        cv["dic"] = dic
        cv["total_count"] = total_count
        finalArr.push(cv)
      }
    }
    return finalArr
  }

  function getAccuracyWords(result, list_words){
    let text = " " + result.filename.toUpperCase() + " " + result.text.toUpperCase() + " " + result.email.toUpperCase() + " ";
    let dic = {}
    let total_count = 0
    for(let i = 0; i < list_words.length; i++){
       const word = list_words[i].toUpperCase()
       let regex = new RegExp("[^A-Z]" + word + "[^A-Z]", 'gi' );
       let count = (text.match(regex) || []).length;
       if (count > 0) {
         dic[list_words[i]] = count
         total_count = total_count + count
       }
    }
    let accuracy = Math.round((Object.keys(dic).length / list_words.length) * 100)
    return {accuracy, dic, total_count}
  }

  // router.post('/displayResultBlacklist',[authJwt.verifyToken], async (req, res) => {
  //   const arrVal = req.body
  //   const finalArr = getListResultBlacklist(arrVal)
  //   finalArr.then(function(result){
  //     result.sort(function (a, b) {
  //         return b.total_count - a.total_count;
  //       });
  
  //     result.sort(function (a, b) {
  //       return b.accuracy - a.accuracy;
  //     });
  //     res.json(result)
  //   })
  // });

  const onSearchResumes = async () => {
    const arrVal = getArrFromSearch(keyword)
    setSearched(true)
    setIsLoading(true);
    // const url = "http://mongo.findme.2kfe.fr/api/functions_database/displayResultBlacklist";
    // const cursor = await axios.post(url, finalArr, { headers: authHeader() });
    const url = "http://mongo.findme.2kfe.fr/api/functions_database/getValidation";
    const cursor = await axios.post(url, {}, { headers: authHeader() });


    const finalArr = getListResultBlacklist(arrVal)
    finalArr.then(function(result){
      result.sort(function (a, b) {
          return b.total_count - a.total_count;
        });
  
      result.sort(function (a, b) {
        return b.accuracy - a.accuracy;
      });
      setResumesBlacklistSearch(result)
    })
    
    setIsLoading(false);
  };



  let data;
  if(isLoading === true){
    data = <div style={{display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30vh'}}>
      <CircularProgress />
    </div>
  } else if(!searched && resumesBlacklist.length > 0){
    data = resumesBlacklist.map(pers => {
     return <PresCVSansDic
       key={pers.filename}
       filename={pers.filename}
       email={pers.email}
       addedTime={pers.addedTime}
       blacklist={pers.blacklist}
       accuracy={100}
       resumes={resumesBlacklist}
       setResumes={setResumesBlacklist}
       base64={pers.base64}
       mimeType={pers.mimeType}
       allResumes={props.allResumes}
       setAllResumes={props.setAllResumes}
     />
   })
 } else if((!searched && resumesBlacklist.length < 1) || (searched && resumesBlacklistSearch.length < 1)){
   data = <div style={{color: "#525252", fontSize:18, marginLeft:'50px'}}> Aucun résultat à afficher</div>
 } else {
   data = resumesBlacklistSearch.map(pers => {
    return <PresentationCV_updated
      key={pers.filename}
      filename={pers.filename}
      email={pers.email}
      addedTime={pers.addedTime}
      blacklist={pers.blacklist}
      accuracy={pers.accuracy}
      dic={pers.dic}
      base64={pers.base64}
      mimeType={pers.mimeType}
      resumes={resumesBlacklistSearch}
      setResumes={setResumesBlacklistSearch}
      blacklistList={resumesBlacklist}
      setBlacklist={setResumesBlacklist}
      allResumes={props.allResumes}
      setAllResumes={props.setAllResumes}
    />
  })
 }

  return (
    <div>
      <div className={styles.search}>
        <TextField
          id="outlined-basic"
          label="Entrez des mots clés, en separant avec une virgule vos expressions"
          className={styles.searchBar}
          variant="outlined"
          value={keyword}
          onChange={onChangeKeyword}
          onKeyPress={handleKeyPress}
          type="text"
        />
        <Button variant="contained" disabled={!keyword} onClick={onSearchResumes} className={styles.searchButton}>Rechercher</Button>
      </div>
      {data}
    </div>
  )
}

import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import PresentationCV from "./PresentationCV"
import styles from "./styles.module.css";
import Button from '@mui/material/Button';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';

import authHeader from "../../services/auth-header"

export default function GeneralSearch(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [resumes, setResumes] = useState([]);
  // const [allResumes, setAllResumes] = useState([])

  // useEffect(() => {
  //   async function fetchAllData() {
  //     setIsLoading(true)
  //     const url = "http://mongo.findme.2kfe.fr/api/functions_database/getAll";
  //     const cursor = await axios.post(url, {}, { headers: authHeader() });
  //     let data = cursor.data
  //     console.log(data)
  //     setAllResumes(data)
  //     setIsLoading(false)
  //   }
  //   fetchAllData();
  // }, []);


   const onSearchResumes = async () => {
    setIsLoading(true);
    // console.log("blabla")
    const url = "http://mongo.findme.2kfe.fr/api/functions_database/getValidation";
    const cursor = await axios.post(url, {}, { headers: authHeader() });
    // console.log("blabla2")
    // console.log(props.allResumes)
    const listWords = getArrFromSearch(keyword)
    const results = props.allResumes
    let finalArr = []
    for(let i=0; i < results.length; i++){
      const cv = results[i]
      const {accuracy, dic, total_count} = getAccuracyWords(cv, listWords)
      if(accuracy > 0){
        cv["accuracy"] = accuracy
        cv["dic"] = dic
        cv["total_count"] = total_count
        finalArr.push(cv)
      }
    }
    finalArr.sort(function(a, b) {
      return b.total_count - a.total_count;
    });
    finalArr.sort(function(a, b) {
      return b.accuracy - a.accuracy;
    });
    setResumes(finalArr)
    
    setIsLoading(false);
  };


  function getAccuracyWords(result, list_words){
    let text = " " + result.filename.toUpperCase() + " " + result.text.toUpperCase() + " " + result.email.toUpperCase() + " ";
    let dic = {}
    let total_count = 0
    for(let i = 0; i < list_words.length; i++){
       const word = list_words[i].toUpperCase()
       let regex = new RegExp("[^A-Z]" + word + "[^A-Z]", 'gi' );
       let count = (text.match(regex) || []).length;
       if (count > 0) {
         dic[list_words[i]] = count
         total_count = total_count + count
       }
    }
    let accuracy = Math.round((Object.keys(dic).length / list_words.length) * 100)
    return {accuracy, dic, total_count}
  }

  const onChangeKeyword = (event) => {
    setKeyword(event.target.value);
  };

  const handleKeyPress = (event) => {
    if(event.key === 'Enter' && event.target.value.length > 0){
      setKeyword(keyword)
      onSearchResumes(keyword)
    }
  }

  function trimSpace(word) {
    return word.trim();
  }


  function getArrFromSearch(inputValue){
    let arr = inputValue.split(',')
    arr = arr.map(trimSpace)
    arr = arr.filter(arr => arr.length > 0)
    return arr
  }

  // const onSearchResumes = async () => {
  //   const finalArr = getArrFromSearch(keyword)
  //   setIsLoading(true);
  //   const url = "http://mongo.findme.2kfe.fr/api/functions_database/getListResult";
  //   const cursor = await axios.post(url, finalArr, { headers: authHeader() });
  //   console.log(cursor)
  //   console.log(cursor.data)
  //   setResumes(cursor.data)
  //   setIsLoading(false);
  // };

  let data;
  if (resumes.length < 1 && isLoading === false){
      data = <div style={{color: "#525252", fontSize:18, marginLeft:'50px'}}> Aucun résultat à afficher</div>
  } else if ( isLoading === true){
    data = <div style={{display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30vh'}}>
      <CircularProgress />
    </div>
  } else {
    data = resumes.map(pers => {
      return <PresentationCV
        key={pers.filename}
        filename={pers.filename}
        email={pers.email}
        addedTime={pers.addedTime}
        blacklist={pers.blacklist}
        accuracy={pers.accuracy}
        dic={pers.dic}
        base64={pers.base64}
        mimeType={pers.mimeType}
        resumes={resumes}
        setResumes={setResumes}
        allResumes={props.allResumes}
        setAllResumes={props.setAllResumes}
      />
    })
  }

  return (
    <div>
      <div className={styles.search}>
        <TextField
          id="outlined-basic"
          label="Entrez des mots clés, en separant avec une virgule vos expressions"
          className={styles.searchBar}
          variant="outlined"
          value={keyword}
          onChange={onChangeKeyword}
          onKeyPress={handleKeyPress}
          type="text"
          />
        <Button variant="contained" disabled={!keyword} onClick={onSearchResumes} className={styles.searchButton}>Rechercher</Button>
      </div>

      <div>
        {data}
      </div>
    </div>
  )
}

import * as React from 'react';
import styles from "./styles.module.css";

export default function LinearGauge(props){
  const val = props.accuracy + "%"

  return (
    <div className={styles.linear_response}>
      <div className={styles.parent}>
        <div className={styles.original_rectangle}></div>
        <div className={styles.original_rectangle, styles.accuracy}
            style={{'background': props.accuracy < 33 ? 'red': props.accuracy > 66 ? 'green' : "orange", width: val}}></div>
      </div>
      <p className={styles.accuracy_val}>{props.accuracy}%</p>
    </div>
  )}

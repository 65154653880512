import React, { useState }  from "react"
import LinearGauge from "../../elements/LinearGauge"
import Liste_words_acc from "../../elements/Liste_words_acc"
import styles from "./styles.module.css";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { blue, red } from '@mui/material/colors';
import axios from "axios";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import authHeader from "../../services/auth-header"

const ColorButtonBlue = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[50]),
  backgroundColor: blue[50],
  '&:hover': {
    backgroundColor: blue[100],
  },
	marginRight:'20px',
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[50]),
  backgroundColor: red[50],
  '&:hover': {
    backgroundColor: red[100],
  },
	marginRight:'20px',
}));

function updateDeleteCV(filename, setResumes, resumes, setAllResumes, allResumes){
  const newData = resumes.filter(object => {
    return object.filename !== filename;
  });

  setResumes(newData);

  const newAllData = allResumes.filter(object => {
    return object.filename !== filename;
  });

  setAllResumes(newAllData);

};

export default function SupprimerCV(props) {

  const [openDialog, setOpenDialog] = useState(false)

  const base64toBlob = (mimeType, base64) => {
      const bytes = atob(base64);
      let length = bytes.length;
      let out = new Uint8Array(length);
      while (length--) {
          out[length] = bytes.charCodeAt(length);
      }
      return new Blob([out], { type: mimeType });
  };

  const openFileInTab = async () => {
    const url = "http://mongo.findme.2kfe.fr/api/functions_database/getBase64";
    const cursor = await axios.post(url, {filename: props.filename}, { headers: authHeader() });
    const base64 = cursor.data
    const mimeType = props.mimeType
    const blob = base64toBlob(mimeType, base64);
    const urlFile = URL.createObjectURL(blob)
    window.open(urlFile, '_blank', 'noopener,noreferrer');
  }

  const deleteCV = async () => {
    const url = "http://mongo.findme.2kfe.fr/api/functions_database/delete";
    await axios.post(url, {filename: props.filename}, { headers: authHeader() });
  };

  const openDialogPopUp = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

    return (
      <div>
        <div className={styles.CV_pres}>
          <div className={styles.person_pres}>
            <b style ={{'color': props.blacklist ? 'red' : "black", fontFamily:'Arial'}}>{props.filename}</b>
            <p style ={{fontFamily:'Arial'}}>{props.email}</p>
            <p style ={{fontFamily:'Arial'}}>Ajouté le {props.addedTime}</p>
          </div>

          <ColorButtonBlue variant="outlined" className={styles.Ouvrir_fichier} onClick={openFileInTab}>Ouvrir</ColorButtonBlue>
          <div>
            <LinearGauge accuracy = {props.accuracy}/>
            <Liste_words_acc
              key={props.filename}
              dic={props.dic}
            />
          </div>

          <ColorButtonRed variant="outlined" color="error" onClick={() => {openDialogPopUp()}} startIcon={<DeleteIcon />} className={styles.rem_blacklister}>Supprimer</ColorButtonRed>
        </div>
        <hr  style={{
            color: '#000000',
            marginLeft: 80,
            marginRight: 80,
            opacity:0.3
        }}/>

        <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Etes vous sûr de vouloir supprimer ce CV: ${props.filename}`}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={() => {deleteCV(props); updateDeleteCV(props.filename, props.setResumes, props.resumes, props.setAllResumes, props.allResumes); handleClose()}} autoFocus>
            Oui, supprimer
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    )
}

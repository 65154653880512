// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../nodevenv/findme/react-jwt-auth/16/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../nodevenv/findme/react-jwt-auth/16/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_linear_response__NAFC3 {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.styles_parent__aYQVA {\n    position: relative;\n    width: 170px;\n    height: 20px;\n    border-style: solid;\n    border-width: 1px;\n    border-radius: 5px;\n    /* border-color: black; */\n}\n.styles_original_rectangle__JNu2z {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    opacity: 0.1;\n    border-radius: 5px;\n}\n.styles_accuracy__qZUTP {\n    z-index: 0;\n    /* // width: 75%; */\n    height:100%;\n    border-radius: 5px;\n    opacity:0.7;\n    background: green;\n}\n\n.styles_accuracy_val__hJt0p {\n  margin-left: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/elements/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;AAC7B;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,iBAAiB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".linear_response {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.parent {\n    position: relative;\n    width: 170px;\n    height: 20px;\n    border-style: solid;\n    border-width: 1px;\n    border-radius: 5px;\n    /* border-color: black; */\n}\n.original_rectangle {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    opacity: 0.1;\n    border-radius: 5px;\n}\n.accuracy {\n    z-index: 0;\n    /* // width: 75%; */\n    height:100%;\n    border-radius: 5px;\n    opacity:0.7;\n    background: green;\n}\n\n.accuracy_val {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linear_response": "styles_linear_response__NAFC3",
	"parent": "styles_parent__aYQVA",
	"original_rectangle": "styles_original_rectangle__JNu2z",
	"accuracy": "styles_accuracy__qZUTP",
	"accuracy_val": "styles_accuracy_val__hJt0p"
};
export default ___CSS_LOADER_EXPORT___;

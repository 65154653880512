import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import BoardAdmin from "./components/board-admin.component";
import logo from './Findit_logo.png'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import axios from "axios";

import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import authHeader from "./services/auth-header"

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[50]),
  backgroundColor: blue[50],
  '&:hover': {
    backgroundColor: blue[100],
  },
	marginRight:'20px',
  marginBottom:"10px",
}));

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="white">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};


function App() {
  
  // const [logOutPage, setLogOutPage] = useState()
  // PAS SURE DU LOGOUT

  const [showAdminBoard, setShowAdminBoard]= useState(false)
  const [progress, setProgress] = useState(0)
  const [currentUser, setCurrentUser] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [addedFiles, setAddedFiles] = useState(0)
  const [fileNotAdded, setFileNotAdded] = useState([])
  const [allResumes, setAllResumes] = useState([])
  const [openAllData, setOpenAllData] = useState(false)

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user)
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"))

      async function fetchAllData() {
        setOpenAllData(true)
        const url = "http://mongo.findme.2kfe.fr/api/functions_database/getAll";
        const cursor = await axios.post(url, {}, { headers: authHeader() }).catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log("1")
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return
          } 
        });

        if (cursor != undefined){
          let data = cursor.data
          setAllResumes(data)
        }

        // const url = "http://mongo.findme.2kfe.fr/api/functions_database/getAll";
        // let allResumes = [];
        // let batchNumber = 0;
        // let batchSize = 1000;
        // let data = [];

        // do {
        //   console.log(batchNumber)
        //   const response = await axios.post(url, { batchNumber }, { headers: authHeader() }).catch(function (error) {
        //     if (error.response) {
        //       // Request made and server responded
        //       console.log("1")
        //       console.log(error.response.data);
        //       console.log(error.response.status);
        //       console.log(error.response.headers);
        //       return;
        //     } 
        //   });
          
        //   if (response != undefined) {
        //     data = response.data;
        //     allResumes = allResumes.concat(data);
        //     batchNumber++;
        //   }
        // } while(data.length === batchSize);

        // setAllResumes(allResumes);


        setOpenAllData(false)
        
      }
      fetchAllData();

    }

  }, [])


  const logOut = () => {
    AuthService.logout();
    setShowAdminBoard(false)
    setCurrentUser(undefined)
  }

  const handleClose = () => {
    setOpenDialog(false)
  };

  const onSelectImageHandler = async(e) => {

    setOpen(true)
    const files = e.target.files;
    let notAdded = []
    let added = 0;
    let alreadyExists = [];
    let cursor;

    for (let i = 0; i < files.length; i++) {
      if (files[i].name.endsWith('pdf') || files[i].name.endsWith('doc') || files[i].name.endsWith('docx')){
        const date = new Date(files[i].lastModified);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        var addedTime = dd + '/' + mm + '/' + yyyy;

        var formData = new FormData();
        formData.append("file", files[i]);
        formData.append("filename", files[i].name)
        formData.append("addedTime", addedTime)

        const url = "http://mongo.findme.2kfe.fr/api/functions_database/createCV";
        cursor = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...authHeader(),
               }
            }).catch(function (error) {
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return 
              }
            });

        if(cursor != undefined){
          added = added + cursor.data.addedFiles
          alreadyExists.push(cursor.data.alreadyExists)
        } else {
          setOpen(false)
        }

        // added = added + cursor.data.addedFiles
        // alreadyExists.push(cursor.data.alreadyExists)
        
      } else {
        notAdded.push(files[i].name + ": extension non valide")
      }
      setProgress((100/files.length) * (i+1))
    }
    setOpen(false)
    setAddedFiles(added)

    setFileNotAdded(notAdded)

    if (added > 0) {
      setOpenAllData(true)
      const url = "http://mongo.findme.2kfe.fr/api/functions_database/getAll";
      const result = await axios.post(url, {}, { headers: authHeader() })
      let data = result.data
      setAllResumes(data)
      setOpenAllData(false)
    }

    setOpenDialog(true)

}

    return (
      <div>

        <div>
          <nav className="navbar">

            <div className='intro'>
              <img src={logo} alt="Logo" className="logo"/>

                <div className="board">
                {showAdminBoard && (
                    <ColorButton variant="contained" component={Link} to={"/home"}>
            					Home
            				</ColorButton>
                )}

                {showAdminBoard && (
                    <ColorButton variant="contained" component={Link} to={"/admin"}>
            					Admin Board
            				</ColorButton>
                )}

                </div>
              </div>

              <h4 className="nav-title">FindMe, la tête chercheuse de CV</h4>

              <div>
                {showAdminBoard && (
                    <ColorButton variant="contained" component={Link} to={"/register"}>
                      Sign Up
                    </ColorButton>
                )}

                {currentUser && (
                  <ColorButton variant="contained" component="label">
                    Ajouter des CVs
                    <input hidden multiple type="file" id="file" name="file" onChange={onSelectImageHandler}/>
                  </ColorButton>
                )}

                {currentUser && (
                  <ColorButton variant="contained" href="/login" onClick={logOut}>
          					Logout
                  </ColorButton>
                )}
              </div>
            </nav>


        <div>
          <Routes>
            {!currentUser && (<Route exact path="/" element={<Login />} />)}
            {currentUser && (<Route path="/home" element={<Home allResumes={allResumes} setAllResumes={setAllResumes}/>} />)}
            {showAdminBoard && (
              <>
                <Route path="/register" element={<Register />} />
                <Route path="/admin" element={<BoardAdmin />} />
              </>
            )}
            <Route path="*" element={<Navigate to={!currentUser ? "/" : "/home"} />} />
          </Routes>
        </div>
      </div>


      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
        <CircularProgressWithLabel value={progress}/>
      </Backdrop>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openAllData}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${addedFiles} nouveau(x) fichier(s) ont été ajouté.`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              {Object.entries(fileNotAdded).map(([key, value]) =>
                <li key={key}>
                  {value}
                </li>)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      </div>
    );
}

export default App;


// {!currentUser && (<Route exact path="/" element={<Login />} />)}
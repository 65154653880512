import React from 'react';

export default function Liste_words_acc(props){
  const arr = props.dic
  const listItems = Object.entries(arr).map(([key, value]) =>
    <li key={key}>
      {key}: {value} fois
    </li>
  );

  return(
    <ul>
      {listItems}
    </ul>
  )
}

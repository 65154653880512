import React , { useState, useEffect } from 'react';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import UserList from "./UserList"
import authHeader from "../services/auth-header"


const BoardAdmin = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    async function fetchUsers() {
      setIsLoading(true)
      const url = "http://mongo.findme.2kfe.fr/api/functions_database/userListToDisplay";
      const cursor = await axios.post(url, {}, { headers: authHeader() });
      let data = cursor.data
      setUserList(data)
      setIsLoading(false)
    }
    fetchUsers();
  }, []);

  let data;
  if ( isLoading === true){
    data = <div style={{display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30vh'}}>
      <CircularProgress />
    </div>
  } else {
    data = userList.map(pers => {
      return <UserList
        key={pers.username}
        username={pers.username}
        email={pers.email}
        userList={userList}
        setUserList={setUserList}
      />
    })
  }

  return (
    <div style={{
        marginTop: "30px"
    }}>
      <h4 style={{
          marginBottom: "50px",
          marginLeft: "25%",
      }}>Liste des personnes ayant accès à FindMe: </h4>
				{data}
		</div>
  )
}

export default BoardAdmin;
